import styled from "styled-components";
import MainWrapper from "src/components/global/MainWrapper.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

const StyledMainWrapper = styled(MainWrapper)`
  display: flex;
  flex-wrap: wrap;
  margin-top: 100rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 60rem;
  }
`;

export default StyledMainWrapper;
