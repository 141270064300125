import React from "react";

import styled from "styled-components";
import MainWrapper from "src/components/global/MainWrapper.js";
import SectionTitle from "src/components/global/SectionTitle.js";
import ArticleCard from "src/components/global/ArticleCard/ArticleCard.js";
import distances from "src/assets/styles/distances.js";
import { StaticImage } from "gatsby-plugin-image";
const Wrapper = styled.div`
  padding: 50rem 0;
  z-index: 10;
  position: relative;
  margin-top: 100rem;

  .img {
    height: 100%;
  }
`;

const ImgWrapper = styled.div`
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100vw;
`;

const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: ${distances.gap}rem;
`;

const SimilarWrapper = styled.div`
  display: flex;

  flex-wrap: wrap;
  width: 100%;
`;

const SimilarArticles = ({ articles }) => {
  return (
    <Wrapper>
      <ImgWrapper>
        <StaticImage
          className="img"
          objectFit="cover"
          src="../../assets/images/Article_bottom.png"
          placeholder="blurred"
          alt="zdjęcie w tle"
        />
      </ImgWrapper>
      <MainWrapper>
        <StyledSectionTitle>Podobne</StyledSectionTitle>
        <SimilarWrapper>
          {articles.map((article) => (
            <ArticleCard article={article} small />
          ))}
        </SimilarWrapper>
      </MainWrapper>
    </Wrapper>
  );
};

export default SimilarArticles;
