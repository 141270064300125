import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import Nav from "src/components/article/Nav.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import distances from "src/assets/styles/distances.js";
import Header from "src/components/article/Header.js";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery";

import Text from "src/components/article/Text.js";
import SingleImg from "src/components/article/SingleImg.js";
import BulletList from "src/components/article/BulletList.js";
import Youtube from "src/components/article/Youtube.js";
import SliderImg from "src/components/article/SliderImg.js";
import SimilarArticles from "src/components/article/SimilarArticles.js";
import Quote from "src/components/article/Quote.js";
import Twitter from "src/components/article/Twitter.js";
import SketchRight from "src/components/SketchRight.js";
import SketchLeft from "src/components/SketchLeft.js";

import Seo from "src/components/global/Seo.js";

const StyledMainWrapper = styled(MainWrapper)`
  display: flex;
  flex-wrap: wrap;
`;

const LeftCol = styled.div`
  width: ${distances.gap}rem;

  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  @media (max-width: ${mediaQuery.laptop}) {
    display: none;
  }
`;
const RightCol = styled.div`
  width: calc(33.33% + ${distances.pageMarginBig + distances.gap}rem);
  height: 100%;

  z-index: 10;
  position: fixed;
  right: ${0}rem;
  top: 0;
  pointer-events: none;

  @media (max-width: ${mediaQuery.laptop}) {
    display: none;
  }
`;

const LeftContent = styled.div`
  width: calc(66.66% - ${distances.gap}rem);
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
`;

const MainImg = styled(Img)`
  width: calc(100% - 6rem);
  border: solid 3rem ${colors.dark};
  z-index: 20;
`;

const articleLayout = ({ data }) => {
  return (
    <>
      <Seo
        title={`${data.strapiArticle.Title} | ds_zine`}
        description={data.strapiArticle.Subtitle}
        image={data.strapiArticle.Main_img.localFile.publicURL}
      />
      <Nav
        title={data.strapiArticle.Title}
        author={data.strapiArticle.author.Name}
        avatar={data.strapiArticle.author.Avatar}
      />

      <LeftCol>
        <SketchLeft />
      </LeftCol>
      <StyledMainWrapper>
        <RightCol>
          <SketchRight />
        </RightCol>
        <LeftContent>
          <Header
            title={data.strapiArticle.Title}
            author={data.strapiArticle.author.Name}
            avatar={data.strapiArticle.author.Avatar}
            subtitle={data.strapiArticle.Subtitle}
            content={data.strapiArticle.Content}
            level={data.strapiArticle.level.Level}
            publishedAt={data.strapiArticle.publishedAt}
            tags={data.strapiArticle.tags}
          />
        </LeftContent>
      </StyledMainWrapper>
      <MainImg
        fluid={data.strapiArticle.Main_img.localFile.childImageSharp.fluid}
        objectFit="cover"
        objectPosition="50% 50%"
        alt={data.strapiArticle.Main_img.alternativeText}
      />

      {data.strapiArticle.Content.map((document) => {
        if (document.strapi_component === "article.text") {
          return (
            <>
              <Text
                hidden={document.Hidden}
                textData={document.Text.data.Text}
                medias={document.Text.medias}
              />
            </>
          );
        }
        if (document.strapi_component === "article.img") {
          return <SingleImg hidden={document.Hidden} img={document.Img} />;
        }
        if (document.strapi_component === "article.bullet-list") {
          return (
            <BulletList hidden={document.Hidden} bullets={document.Bullet} />
          );
        }
        if (document.strapi_component === "article.youtube") {
          return <Youtube hidden={document.Hidden} src={document.Src} />;
        }
        if (document.strapi_component === "article.quote") {
          return <Quote hidden={document.Hidden} textData={document.Quote} />;
        }
        if (document.strapi_component === "article.slider") {
          return (
            <>
              <SliderImg images={document.Images} />{" "}
            </>
          );
        }
        if (document.strapi_component === "article.twitter") {
          return (
            <>
              <Twitter
                hidden={document.Hidden}
                twitter_id={document.Twitter_id}
              />
            </>
          );
        }
      })}
      {data.strapiArticle.similar.length >= 1 && (
        <SimilarArticles articles={data.strapiArticle.similar} />
      )}
      {console.log(data.strapiArticle.similar.length)}
    </>
  );
};

export const query = graphql`
  query ArticleLayout($id: String!) {
    strapiArticle(id: { eq: $id }) {
      Title

      Subtitle
      publishedAt(formatString: "DD.MM.YY")
      level {
        Level
      }
      Content {
        ... on STRAPI__COMPONENT_ARTICLE_TEXT {
          strapi_component
          Hidden
          Text {
            data {
              Text
            }
            medias {
              url
              file {
                caption
                alternativeText
              }
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_ARTICLE_IMG {
          strapi_component
          Hidden
          Img {
            caption
            alternativeText
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_ARTICLE_BULLET_LIST {
          Bullet {
            Text
          }
          strapi_component
        }
        ... on STRAPI__COMPONENT_ARTICLE_YOUTUBE {
          Src
          Hidden
          strapi_component
        }
        ... on STRAPI__COMPONENT_ARTICLE_SLIDER {
          strapi_component
          Images {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            caption
            alternativeText
            width
            height
          }
        }
        ... on STRAPI__COMPONENT_ARTICLE_QUOTE {
          Hidden
          Quote
          strapi_component
        }
        ... on STRAPI__COMPONENT_ARTICLE_TWITTER {
          Hidden
          Twitter_id
          strapi_component
        }
      }
      Main_img {
        alternativeText
        localFile {
          publicURL
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      author {
        Name
        Avatar {
          alternativeText
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      tags {
        Name
      }
      similar {
        Title
        Twitter_badge
        Subtitle
        publishedAt(formatString: "DD.MM.YY")
        level {
          Level
        }
        Content {
          ... on STRAPI__COMPONENT_ARTICLE_TEXT {
            Text {
              data {
                Text
              }
            }
          }
        }
        Main_img {
          alternativeText
          localFile {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        author {
          Name
          Avatar {
            alternativeText
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        tags {
          Name
        }
      }
    }
  }
`;

export default articleLayout;
