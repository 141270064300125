import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes.js";

const StyledFigcaption = styled.figcaption`
  font-size: ${typographySizes.xs}rem;
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
  margin-top: 10rem;
  font-weight: 600;
`;

export default StyledFigcaption;
