import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes.js";
import mediaQuery from "src/assets/styles/mediaQuery";

const Hidden = styled.span`
  font-size: ${typographySizes.sm}rem;
  font-family: "DM Serif Display", serif;
  width: 80%;
  margin: 0 auto;
  display: inline-block;
  text-align: center;
  @media (max-width: ${mediaQuery.laptop}) {
    display: none;
  }
`;

export default Hidden;
