import styled from "styled-components";
import distances from "src/assets/styles/distances.js";
import mediaQuery from "src/assets/styles/mediaQuery";

const LeftContent = styled.div`
  width: calc(66.66% - ${distances.gap}rem);
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
`;

export default LeftContent;
