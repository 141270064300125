import React from "react";

import styled from "styled-components";
import mediaQuery from "src/assets/styles/mediaQuery";
import typographySizes from "src/assets/styles/typographySizes.js";
import noHangers from "src/components/global/fn/noHangers.js";
import RightContent from "src/components/article/ContentComponents/RightContent.js";
import LeftContent from "src/components/article/ContentComponents/LeftContent.js";
import RowWrapper from "src/components/article/ContentComponents/RowWrapper.js";
import Hidden from "src/components/article/ContentComponents/Hidden.js";
import distances from "src/assets/styles/distances.js";
import star from "src/assets/svg/bullet.svg";
import Img from "gatsby-image";
import colors from "src/assets/styles/colors.js";
import StyledFigcaption from "src/components/article/ContentComponents/StyledFigcaption.js";

import Markdown from "markdown-to-jsx";

import DictionaryComponent from "src/components/article/ContentComponents/DictionaryComponent.js";

const TextWrapper = styled.div`
  font-family: "Open Sans", sans-serif;
  line-height: 1.6;
  width: 75%;

  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
  pre {
    background: #fff5f5;
    /* word-wrap: break-word; */
    box-decoration-break: clone;
    padding: 30rem;
    border-radius: 0.2rem;
    margin: 30rem 0;
    overflow-y: scroll;
    font-family: "Roboto Mono";
    code {
      font-family: "Roboto Mono";
    }
  }
  .markdown {
    font-size: ${typographySizes.sm}rem;
    font-weight: 400;

    h1 {
      font-size: ${typographySizes.l}rem;
      line-height: 1.2;
      font-family: "DM Serif Display", serif;
      margin-bottom: 15rem;
    }
    h2 {
      font-size: ${typographySizes.m}rem;
      line-height: 1.2;
      font-family: "DM Serif Display", serif;
      margin-bottom: 15rem;
    }
    h3,
    h4,
    h5,
    h6 {
      font-size: ${typographySizes.m}rem;
      font-family: "DM Serif Display", serif;
      margin-bottom: 15rem;
      line-height: 1.2;
    }

    p {
      margin-bottom: 30px;
      font-weight: 400;
      font-size: ${typographySizes.sm}rem;
      :last-of-type {
        margin-bottom: 0;
      }
    }
    strong {
      font-weight: 600;
      font-size: inherit;
    }
    em {
      font-style: italic;
      font-size: inherit;
    }
    a {
      font-size: inherit;
      color: #6400b4;

      text-decoration: underline;
    }
    /* img {
      width: 100%;

      border: solid 3rem ${colors.dark};
    } */

    ul {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 50rem 0;
    }
    li {
      font-size: ${typographySizes.sm}rem;
      font-family: "DM Serif Display", serif;
      position: relative;
      padding-left: 62.5rem;
      margin-bottom: ${distances.gap}rem;
      width: calc(50% - 62.5rem - ${distances.gap}rem);
      margin-right: ${distances.gap}rem;
      ::before {
        content: "";
        position: absolute;
        top: -5rem;
        left: -1rem;
        background-image: url(${star});
        height: 45rem;
        width: 32.5rem;
        background-repeat: none;
        background-position: left center;
        z-index: 2;
        top: -10rem;

        transform: scale(0.7);
      }
      :last-of-type {
        margin-bottom: 0;
      }
      @media (max-width: ${mediaQuery.tablet}) {
        width: 100%;
        margin-bottom: ${distances.gap * 2}rem;
      }
      @media (max-width: ${mediaQuery.mobile}) {
        font-size: ${typographySizes.s}rem;
        padding-left: 30rem;
        margin-bottom: ${distances.gap}rem;
        width: 100%;
        margin-right: 0;
        ::before {
          top: -12rem;
          left: -10rem;
          transform: scale(0.5);
        }
      }
    }
  }
`;

const MyCode = ({ children, ...props }) => <code {...props}>{children}</code>;
const StyledImg = styled(Img)`
  border: solid 3rem ${colors.dark};
`;
const MyImg = ({ ...props }) => {
  let curImg = null;
  props.gatsbyimg.forEach(function (imgMedia) {
    if (imgMedia.url === props.src) {
      curImg = imgMedia;
    }
  });
  return (
    <>
      {curImg && (
        <figure>
          <StyledImg
            fluid={curImg.localFile.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt={curImg.file.alternativeText}
          />
          <StyledFigcaption>{curImg.file.caption}</StyledFigcaption>
        </figure>
      )}
    </>
  );
};
const Text = ({ textData, hidden, medias }) => {
  const mediasArr = [];

  medias.forEach(function (imgMedia) {
    mediasArr.push(imgMedia);
  });

  return (
    <RowWrapper>
      <LeftContent>
        <TextWrapper>
          <Markdown
            className={"markdown"}
            options={{
              overrides: {
                Dic: {
                  component: DictionaryComponent,
                },
                code: {
                  component: MyCode,
                  props: {
                    className: "foo",
                  },
                },
                img: {
                  component: MyImg,
                  props: {
                    medias: medias,
                    gatsbyimg: mediasArr,
                  },
                },
              },
            }}
          >
            {noHangers(textData)}
          </Markdown>
        </TextWrapper>
      </LeftContent>
      <RightContent>
        <Hidden>{hidden}</Hidden>
      </RightContent>
    </RowWrapper>
  );
};

export default Text;
