import React, { Component } from "react";
import styled from "styled-components";

import colors from "src/assets/styles/colors.js";


const Progress = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(100, 0, 179, 1) 0%,
    rgba(134, 184, 255, 1) 51%,
    rgba(211, 90, 255, 1) 100%
  );
  width: ${(props) => props.scroll};
  height: 3rem;
  z-index: 3;
`;

const ProgressBg = styled.div`
  height: 3rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${colors.dark};
`;

export default class ProgressBar extends Component {
  state = {
    scrollPostion: 0,
  };

  listenToScrollEvent = () => {
    document.addEventListener("scroll", () => {
      requestAnimationFrame(() => {
        this.calculateScrollDistance();
      });
    });
  };

  calculateScrollDistance = () => {
    const scrollTop = window.pageYOffset; // how much the user has scrolled by
    const winHeight = window.innerHeight;
    const docHeight = this.getDocHeight();

    const totalDocScrollLength = docHeight - winHeight;
    const scrollPostion = (scrollTop / totalDocScrollLength) * 100;

    this.setState({
      scrollPostion,
    });
  };

  getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
  };

  componentDidMount() {
    this.listenToScrollEvent();
  }
  render() {
    return (
      <>
        <ProgressBg />
        <Progress scroll={this.state.scrollPostion + "%"} />
      </>
    );
  }
}
