import React from "react";

import styled from "styled-components";
import mediaQuery from "src/assets/styles/mediaQuery";
import typographySizes from "src/assets/styles/typographySizes.js";
import noHangers from "src/components/global/fn/noHangers.js";
import RightContent from "src/components/article/ContentComponents/RightContent.js";
import LeftContent from "src/components/article/ContentComponents/LeftContent.js";
import RowWrapper from "src/components/article/ContentComponents/RowWrapper.js";
import Hidden from "src/components/article/ContentComponents/Hidden.js";
import Star from "src/assets/svg/star-half-quote.inline.svg";

const QuoteWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 40rem 0;
`;
const BracketWrapper = styled.div`
  width: 12.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const QuoteText = styled.h5`
  font-size: ${typographySizes.l}rem;

  font-family: "DM Serif Display", serif;
  font-style: italic;
  width: 87.5%;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.m}rem;
  }
`;

const TopStar = styled(Star)`
  transform: translateY(-100%);
`;

const BottomStar = styled(Star)`
  transform: rotate(180deg) translateY(-100%);
`;

const Quote = ({ textData, hidden }) => {
  return (
    <RowWrapper>
      <LeftContent>
        <QuoteWrapper>
          <BracketWrapper>
            <TopStar />
            <BottomStar />
          </BracketWrapper>
          <QuoteText>{noHangers(textData)}</QuoteText>
        </QuoteWrapper>
      </LeftContent>
      <RightContent>
        <Hidden>{noHangers(hidden)}</Hidden>
      </RightContent>
    </RowWrapper>
  );
};

export default Quote;
