import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Fb from "src/assets/svg/fb-icon.inline.svg";
import Twitter from "src/assets/svg/twitter-icon.inline.svg";

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  margin-left: 30rem;
`;

const LinkWrapper = styled.a`
  margin-left: 10rem;
`;

const SocialShare = () => {
  const [url, setUrl] = useState(false);
  const isBrowser = typeof window !== "undefined";
  useEffect(() => {
    if (isBrowser) {
      setUrl(window.location.href);
    }
  }, []);
  return (
    <Wrapper>
      <LinkWrapper
        href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
        target="_blank"
      >
        <Fb />
      </LinkWrapper>
      <LinkWrapper
        // className="twitter-share-button"
        href={`https://twitter.com/intent/tweet?url=${url}`}
        target="_blank"
      >
        <Twitter />
      </LinkWrapper>
    </Wrapper>
  );
};

export default SocialShare;
