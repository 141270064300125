import React, { useEffect, useState } from "react";
import styled from "styled-components";
import distances from "src/assets/styles/distances.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import Author from "src/components/global/Author.js";
import Paragraph from "src/components/global/Paragraph.js";
import InfoBox from "src/components/global/ArticleCard/InfoBox.js";
import SocialShare from "src/components/article/SocialShare.js";
import Tags from "src/components/global/Tags.js";
import mediaQuery from "src/assets/styles/mediaQuery";

const Wrapper = styled.div`
  margin: ${distances.gap * 4}rem 0;
  @media (max-width: ${mediaQuery.mobile}) {
    margin: ${distances.gap * 2}rem 0;
  }
`;

const Toprow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: ${typographySizes.l}rem;
  font-family: "DM Serif Display", serif;
  margin-top: ${distances.gap}rem;
  margin-bottom: 15rem;
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: ${typographySizes.m}rem;
  }
`;

const Subtitle = styled(Paragraph)`
  width: 62.5%;
  margin-bottom: ${distances.gap}rem;
  font-size: ${typographySizes.sm}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const BottomWrapper = styled.div`
  margin-top: ${distances.gap * 2}rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100%);
  @media (max-width: ${mediaQuery.mobile}) {
    margin-top: ${distances.gap * 2}rem;
  }
`;

const BionicReading = styled.a`
  font-size: ${typographySizes.s}rem;
  margin-bottom: ${distances.gap}rem;
  display: inline-block;
  /* text-decoration: none; */
  span {
    font-size: inherit;
    font-weight: bold;
  }
`;

const Header = ({
  title,
  avatar,
  author,
  subtitle,
  publishedAt,
  level,
  content,
  tags,
}) => {
  const [url, setUrl] = useState(false);
  const isBrowser = typeof window !== "undefined";
  useEffect(() => {
    if (isBrowser) {
      setUrl(window.location.href);
    }
  }, []);
  return (
    <>
      <Wrapper>
        <Toprow>
          <Author
            avatar={avatar.localFile.childImageSharp.fluid}
            author={author}
            alt={avatar.alternativeText}
          />
        </Toprow>

        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <InfoBox
          date={publishedAt}
          level={level}
          article={content}
          small
          bottom
        />
        <BottomWrapper>
          <Tags relative tags={tags} />

          <SocialShare />
        </BottomWrapper>
      </Wrapper>
      <BionicReading
        href={`https://api.bionic-reading.com/convert/?url=${url}`}
        target="_blank"
      >
        <span>Bio</span>nic <span>Read</span>ing &nbsp;&nbsp;👀
      </BionicReading>
    </>
  );
};

export default Header;
