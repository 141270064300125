import React from "react";

import styled from "styled-components";
import Img from "gatsby-image";
import RightContent from "src/components/article/ContentComponents/RightContent.js";
import LeftContent from "src/components/article/ContentComponents/LeftContent.js";
import RowWrapper from "src/components/article/ContentComponents/RowWrapper.js";
import Hidden from "src/components/article/ContentComponents/Hidden.js";
import colors from "src/assets/styles/colors.js";
import StyledFigcaption from "src/components/article/ContentComponents/StyledFigcaption.js";

const StyledImg = styled(Img)`
  border: solid 3rem ${colors.dark};
`;

const SingleImg = ({ img, hidden }) => {
  return (
    <RowWrapper>
      <LeftContent>
        <figure>
          <StyledImg
            fluid={img.localFile.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt={img.alternativeText}
          />
          <StyledFigcaption>{img.caption}</StyledFigcaption>
        </figure>
      </LeftContent>

      <RightContent>
        <Hidden>{hidden}</Hidden>
      </RightContent>
    </RowWrapper>
  );
};

export default SingleImg;
