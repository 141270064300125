import React from "react";

import styled from "styled-components";
import RightContent from "src/components/article/ContentComponents/RightContent.js";
import LeftContent from "src/components/article/ContentComponents/LeftContent.js";
import RowWrapper from "src/components/article/ContentComponents/RowWrapper.js";
import Hidden from "src/components/article/ContentComponents/Hidden.js";


const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;

  width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Youtube = ({ src, hidden }) => {
  return (
    <RowWrapper>
      <LeftContent>
        {" "}
        <VideoWrapper>
          {" "}
          <iframe
            width="100%"
            height="315"
            src={src}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </VideoWrapper>
      </LeftContent>

      <RightContent>
        <Hidden>{hidden}</Hidden>
      </RightContent>
    </RowWrapper>
  );
};

export default Youtube;
