import styled from "styled-components";
import distances from "src/assets/styles/distances.js";

const RightContent = styled.div`
  width: calc(33.33%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${distances.gap}rem;
  position: relative;
`;

export default RightContent;
