import React from "react";

import styled from "styled-components";
import mediaQuery from "src/assets/styles/mediaQuery";
import RightContent from "src/components/article/ContentComponents/RightContent.js";
import LeftContent from "src/components/article/ContentComponents/LeftContent.js";
import RowWrapper from "src/components/article/ContentComponents/RowWrapper.js";
import Hidden from "src/components/article/ContentComponents/Hidden.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import star from "src/assets/svg/bullet.svg";
import distances from "src/assets/styles/distances.js";
import { TwitterTweetEmbed } from "react-twitter-embed";

const StyledLeftContent = styled(LeftContent)`
  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const Twitter = ({ hidden, twitter_id }) => {
  return (
    <RowWrapper>
      <StyledLeftContent>
        <TwitterTweetEmbed tweetId={twitter_id} />{" "}
      </StyledLeftContent>

      <RightContent>
        <Hidden>{hidden}</Hidden>
      </RightContent>
    </RowWrapper>
  );
};

export default Twitter;
