import React from "react";
// import Sketch from "react-p5";

const Sketch = React.lazy(() => import("react-p5"));

function SketchRight(p5, canvasParentRef) {
  let squareSize = 30;
  let count = p5.windowWidth / 30;
  let widthC = squareSize * count;
  let heightC = squareSize * count;
  let vechicles = [];
  let odd = true;
  let fillIt;

  var height = canvasParentRef.offsetHeight + 100;

  const setup = (p5, canvasParentRef) => {
    vechicles = [];
    p5.createCanvas(p5.windowWidth + 30, p5.windowHeight).parent(
      canvasParentRef
    );
    p5.background(255);

    for (var y = 0; y < p5.windowHeight + squareSize; y += squareSize) {
      for (var x = 120; x < p5.windowWidth; x += squareSize) {
        if (odd) {
          fillIt = true;
          odd = false;
        } else {
          fillIt = false;

          odd = true;
        }
        let vechicle = new Vechicle(x, y, squareSize, fillIt, p5);
        vechicles.push(vechicle);
      }
    }

    // p5.createCanvas(500, 400).parent(canvasParentRef);
  };

  const draw = (p5) => {
    // console.log(p5.frameCount);
    // p5.background(255, 130, 20);
    // p5.ellipse(100, 100, 100);
    // p5.ellipse(300, 100, 100);
    // p5.background(255);
    p5.clear();

    p5.fill(255);

    for (let i = 0; i < vechicles.length; i++) {
      vechicles[i].show(p5);
      vechicles[i].behaviors(p5);
      vechicles[i].update(p5);
    }
    p5.fill(351, 60, 90);
  };

  function Vechicle(x, y, size, fillIt, p5) {
    this.pos = p5.createVector(x, y);
    // this.vel = p5.Vector.random2D();
    this.vel = p5.createVector(Math.random(), Math.random(), 0);
    // this.vel = p5.createVector(0.61554617, -0.51195765);
    this.acc = p5.createVector();
    this.target = p5.createVector(x, y);
    this.size = size;
    this.fillIt = fillIt;
    this.maxSpeed = 5;
    this.maxForce = 0.5;
  }

  Vechicle.prototype.behaviors = function (p5) {
    let arrive = this.arrive(this.target, p5);
    let mouse = p5.createVector(p5.mouseX, p5.mouseY);
    let flee = this.flee(mouse, p5);

    flee.mult(5);
    arrive.mult(1);
    this.applyForce(arrive);
    this.applyForce(flee);
  };

  Vechicle.prototype.applyForce = function (f) {
    this.acc.add(f);
  };

  Vechicle.prototype.arrive = function (target, p5) {
    // let desired = p5.p5.Vector.sub(target, this.pos);
    let desired = p5.createVector(target.x - this.pos.x, target.y - this.pos.y);
    let d = desired.mag();
    let speed = this.maxSpeed;
    if (d < 50) {
      speed = p5.map(d, 0, 100, 0, this.maxSpeed);
    }
    desired.setMag(speed);
    // let steering = p5.p5.Vector.sub(desired, this.vel);
    let steering = p5.createVector(
      desired.x - this.vel.x,
      desired.y - this.vel.y
    );
    steering.limit(this.maxForce);
    return steering;
  };

  Vechicle.prototype.flee = function (target, p5) {
    // let desired = p5.p5.Vector.sub(target, this.pos);
    let desired = p5.createVector(target.x - this.pos.x, target.y - this.pos.y);
    let d = desired.mag();
    if (d < 50) {
      desired.setMag(this.maxSpeed);
      desired.mult(-1);
      // let steering = p5.p5.Vector.sub(desired, this.vel);
      let steering = p5.createVector(
        desired.x - this.vel.x,
        desired.y - this.vel.y
      );
      steering.limit(this.maxForce);
      return steering;
    } else {
      return p5.createVector(0, 0);
    }
  };

  Vechicle.prototype.update = function (p5) {
    this.pos.add(this.vel);
    this.vel.add(this.acc);
    this.acc.mult(0);
  };

  Vechicle.prototype.show = function (p5) {
    p5.fill(255);
    p5.stroke(0);

    // let desired = p5.p5.Vector.sub(this.target, this.pos);
    let desired = p5.createVector(
      this.target.x - this.pos.x,
      this.target.y - this.pos.y
    );
    let d = desired.mag();

    // if(d > 5){
    //   fill(d*5,20,d*10);
    // }

    p5.rect(this.pos.x, this.pos.y, this.size, this.size);
  };
  const isSSR = typeof window === "undefined";

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div>Loading...</div>}>
          <Sketch setup={setup} draw={draw} />;
        </React.Suspense>
      )}
    </>
  );
}

export default SketchRight;
