import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Logo from "src/assets/svg/ds-zine-logo-black.inline.svg";
import colors from "src/assets/styles/colors.js";
import noHangers from "src/components/global/fn/noHangers.js";

import star from "src/assets/svg/star_half.svg";
import MainWrapper from "src/components/global/MainWrapper.js";

import distances from "src/assets/styles/distances.js";
import typographySizes from "src/assets/styles/typographySizes.js";

import mediaQuery from "src/assets/styles/mediaQuery";
import ProgressBar from "src/components/article/ProgressBar.js";
import Author from "src/components/global/Author.js";
import SocialShare from "src/components/article/SocialShare.js";
import { Link } from "gatsby";

const Wrapper = styled.div`
  width: 100%;
  background-color: ${colors.white};
  padding-left: ${distances.pageMarginBig - distances.gap}rem;
  left: -${distances.pageMarginBig - distances.gap}rem;
  width: calc(66.66% + 30rem);
  position: relative;
  height: 100rem;
  display: flex;
  z-index: 100;

  align-items: center;
  /* background-color: red; */
  position: relative;
  border-bottom: solid 3rem ${colors.dark};
  ::after {
    content: "";
    position: absolute;
    bottom: -1.5rem;
    right: -1rem;
    background-image: url(${star});
    height: 45rem;
    width: 69rem;
    transform: translateY(50%);
    z-index: 2;
  }
  ::before {
    content: "";
    position: absolute;
    bottom: -1.5rem;
    left: -1rem;
    background-image: url(${star});
    height: 45rem;
    width: 69rem;
    transform: translateY(50%) rotate(180deg);
    z-index: 2;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(100% + ${distances.gap - 1}rem);
    left: -${distances.gap}rem;
    padding-left: ${distances.gap}rem;
  }
`;

const FixNav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100rem;

  z-index: 100;
  padding: 0 ${distances.pageMarginBig}rem;
  width: calc(100% - ${distances.pageMarginBig * 2}rem);
  display: flex;
  align-items: center;
  background-color: ${colors.white};

  transform: translateY(-100%);
  transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(100% - ${distances.gap * 2}rem);
    padding: 0 ${distances.gap}rem;
    display: flex;
  }
  &.scrolled {
    transform: translateY(0%);
    transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const StyledLogo = styled(Logo)`
  width: 254rem;
  @media (max-width: ${mediaQuery.mobile}) {
    width: 200rem;
  }
`;

const LogoWapper = styled.div`
  width: 42.66%;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

const Title = styled.h3`
  font-size: ${typographySizes.sm}rem;
  font-family: "DM Serif Display", serif;
  margin-bottom: 5rem;
  max-width: 33.33%;
  margin-right: ${distances.gap}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    max-width: 50%;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    max-width: 100%;
    font-size: ${typographySizes.s}rem;
    line-height: 1.1;
  }
`;

const Nav = ({ title, avatar, author }) => {
  const isBrowser = typeof window !== "undefined";

  const [navScrolled, setNavScrolled] = useState(false);

  useEffect(() => {
    if (isBrowser) {
      console.log(window.location.href + "test");
      window.onscroll = function (e) {
        let scrollTop =
          window.pageYOffset ||
          (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop;

        scrollTop > 100 ? setNavScrolled(true) : setNavScrolled(false);
      };
    }
  }, []);
  return (
    <>
      <MainWrapper>
        <Wrapper>
          <Link to="/">
            <StyledLogo />
          </Link>
        </Wrapper>
      </MainWrapper>
      <FixNav className={navScrolled && "scrolled"}>
        <LogoWapper>
          <Link to="/">
            <StyledLogo />
          </Link>
        </LogoWapper>

        <Title>{noHangers(title)}</Title>

        <Author
          avatar={avatar.localFile.childImageSharp.fluid}
          author={author}
          mobileOff
          alt={avatar.alternativeText}
        />

        <SocialShare />

        <ProgressBar />
      </FixNav>
    </>
  );
};

export default Nav;
