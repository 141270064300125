import React from "react";

import styled, { keyframes } from "styled-components";
import mediaQuery from "src/assets/styles/mediaQuery";
import Img from "gatsby-image";
import Swipeable from "react-swipeable";
import colors from "src/assets/styles/colors.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import StyledFigcaption from "src/components/article/ContentComponents/StyledFigcaption.js";
import Arrow from "src/assets/svg/arrow.inline.svg";

const OverflowWrapper = styled.div`
  overflow: hidden;
`;

const ImgWrapper = styled.div`
  display: flex;

  margin-bottom: 50rem;
  width: calc(100% - 20rem);

  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;
const ImgWrapperMobile = styled(ImgWrapper)`
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: flex;
  }
`;

const StyledImg = styled(Img)`
  width: ${(props) =>
    props.imgWidth
      ? `calc(${props.imgWidth / props.imgHeight} * 30vw)`
      : "40vw"};

  flex-shrink: 0;
  margin-right: 60rem;
  img {
    object-fit: cover;
  }
  border: solid 3rem ${colors.dark};
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  @media (max-width: ${mediaQuery.tablet}) {
    width: ${(props) =>
      props.imgWidth
        ? `calc(${props.imgWidth / props.imgHeight} * 50vw)`
        : "40vw"};
    margin-right: 30rem;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`;

const ControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: -50rem;
  right: calc(33.33% + 30rem);
  background-color: ${colors.white};
  /* padding: 2rem 5rem; */

  @media (max-width: ${mediaQuery.laptop}) {
    right: 0;
  }
`;

const moveArrowRight = keyframes`
  0% {
    transform: translateX( -0rem);
  }
  50% {
    transform: translateX( 30rem);
  }
  51% {
    transform: translateX( -30rem);
  }
  100% {
    transform: translateX( -0rem)
  }
`;
const moveArrowLeft = keyframes`
  0% {
    transform: translateX( -0rem) rotate(-180deg);;
  }
  50% {
    transform: translateX( -30rem) rotate(-180deg);;
  }
  51% {
    transform: translateX( 30rem) rotate(-180deg);
  }
  100% {
    transform: translateX( -0rem) rotate(-180deg);
  }
`;
const StyledArrow = styled(Arrow)`
  display: inline-block;
`;

const ShowNext = styled.button`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 15rem;
  opacity: 0.2;
  background-color: ${colors.dark};
  height: 32rem;
  width: 32rem;
  border-radius: 100%;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.nondisable {
    opacity: 1;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.nondisable:hover {
    ${StyledArrow} {
      animation: ${moveArrowRight} 0.5s;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
  }
`;

const ShowPrev = styled.button`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.dark};
  height: 32rem;
  width: 32rem;
  border-radius: 100%;
  cursor: pointer;
  opacity: 0.2;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.nondisable {
    opacity: 1;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.nondisable:hover {
    /* transform: translateY(-3rem); */
    ${StyledArrow} {
      animation: ${moveArrowLeft} 0.5s;
    }
  }
  ${StyledArrow} {
    transform: rotate(-180deg);
  }
  @media (max-width: ${mediaQuery.tablet}) {
  }
`;

const SliderWrapper = styled(MainWrapper)`
  height: auto;
  position: relative;
  z-index: 10;
  margin-top: 100rem;

  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 60rem;
  }
`;
class SliderImg extends React.Component {
  state = {
    x: 0,
    curW: 0,
    prevDisable: true,
    nextDisable: false,
    allWidthImg: [],
  };

  componentDidMount() {
    this.props.images.map((document) =>
      this.setState((prevState) => ({
        allWidthImg: [
          ...prevState.allWidthImg,
          document.width / document.height,
        ],
      }))
    );
  }

  changeSlideNext = () => {
    // this.setState({
    //   x: this.state.x + 1,
    //   curW: this.state.curW + this.state.allWidthImg[this.state.x],
    // })

    this.state.x >= this.props.images.length - 1
      ? this.setState({
          x: this.props.images.length - 1,
          curW: this.state.curW,
        })
      : this.setState({
          x: this.state.x + 1,
          curW: this.state.curW + this.state.allWidthImg[this.state.x],
        });

    this.state.x >= this.props.images.length - 2
      ? this.setState({
          nextDisable: true,
        })
      : this.setState({
          nextDisable: false,
        });

    this.state.x >= 0
      ? this.setState({
          prevDisable: false,
        })
      : this.setState({
          prevDisable: true,
        });
  };

  changeSlidePrev = () => {
    this.state.x <= 0
      ? this.setState({
          x: 0,
          curW: 0,
        })
      : this.setState({
          x: this.state.x - 1,
          curW: this.state.curW - this.state.allWidthImg[this.state.x - 1],
        });

    this.state.x <= 1
      ? this.setState({
          prevDisable: true,
        })
      : this.setState({
          prevDisable: false,
        });

    this.state.x > this.props.images.length - 1
      ? this.setState({
          nextDisable: true,
        })
      : this.setState({
          nextDisable: false,
        });
  };

  swipingLeft = (e) => {
    this.changeSlideNext();
    e.preventDefault();
  };
  swipingRight = (e) => {
    this.changeSlidePrev();
    e.preventDefault();
  };

  render() {
    return (
      <OverflowWrapper>
        <Swipeable
          onSwipedLeft={this.swipingLeft}
          preventDefaultTouchmoveEvent={true}
          onSwipedRight={this.swipingRight}
        >
          <SliderWrapper>
            <ImgWrapper sliderWidth={this.props.sliderWidth}>
              {this.props.images.map((document, index) => {
                return (
                  <ImageWrapper
                    style={{
                      transform: `translateX(calc(${
                        this.state.curW
                      } * -30vw - ${this.state.x * 60}px))`,
                    }}
                  >
                    <figure>
                      <StyledImg
                        imgWidth={document.width}
                        imgHeight={document.height}
                        key={index}
                        fluid={document.localFile.childImageSharp.fluid}
                        alt={document.alternativeText}
                      />
                      <StyledFigcaption>{document.caption}</StyledFigcaption>
                    </figure>
                  </ImageWrapper>
                );
              })}
            </ImgWrapper>
            <ImgWrapperMobile sliderWidth={this.props.sliderWidth}>
              {this.props.images.map((document, index) => {
                return (
                  <ImageWrapper
                    style={{
                      transform: `translateX(calc(${
                        this.state.curW
                      } * -50vw - ${this.state.x * 30}px))`,
                    }}
                  >
                    <StyledImg
                      imgWidth={document.width}
                      imgHeight={document.height}
                      key={index}
                      fluid={document.localFile.childImageSharp.fluid}
                    />
                  </ImageWrapper>
                );
              })}
            </ImgWrapperMobile>
            <ControlWrapper sliderWidth={this.props.sliderWidth}>
              <ShowPrev
                className={this.state.prevDisable ? "disable" : "nondisable"}
                onClick={this.changeSlidePrev}
              >
                <StyledArrow />
              </ShowPrev>
              <ShowNext
                className={this.state.nextDisable ? "disable" : "nondisable"}
                onClick={this.changeSlideNext}
              >
                <StyledArrow />
              </ShowNext>
            </ControlWrapper>
          </SliderWrapper>
        </Swipeable>
      </OverflowWrapper>
    );
  }
}

export default SliderImg;
