import React from "react";

import styled from "styled-components";
import mediaQuery from "src/assets/styles/mediaQuery";
import RightContent from "src/components/article/ContentComponents/RightContent.js";
import LeftContent from "src/components/article/ContentComponents/LeftContent.js";
import RowWrapper from "src/components/article/ContentComponents/RowWrapper.js";
import Hidden from "src/components/article/ContentComponents/Hidden.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import star from "src/assets/svg/bullet.svg";
import distances from "src/assets/styles/distances.js";

const BulletWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Bullet = styled.div`
  font-size: ${typographySizes.sm}rem;
  font-family: "DM Serif Display", serif;
  position: relative;
  padding-left: 62.5rem;
  margin-bottom: ${distances.gap}rem;
  width: calc(50% - 62.5rem - ${distances.gap}rem);
  margin-right: ${distances.gap}rem;
  ::before {
    content: "";
    position: absolute;
    top: -5rem;
    left: -1rem;
    background-image: url(${star});
    height: 45rem;
    width: 32.5rem;
    background-repeat: none;
    background-position: left center;
    z-index: 2;
    top: -10rem;

    transform: scale(0.7);
  }
  :last-of-type {
    margin-bottom: 0;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-bottom: ${distances.gap * 2}rem;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: ${typographySizes.s}rem;
    padding-left: 30rem;
    margin-bottom: ${distances.gap}rem;
    width: 100%;
    margin-right: 0;
    ::before {
      top: -12rem;
      left: -10rem;
      transform: scale(0.5);
    }
  }
`;

const BulletList = ({ hidden, bullets }) => {
  return (
    <RowWrapper>
      <LeftContent>
        <BulletWrapper>
          {bullets.map((document) => (
            <Bullet>{document.Text}</Bullet>
          ))}
        </BulletWrapper>
      </LeftContent>

      <RightContent>
        <Hidden>{hidden}</Hidden>
      </RightContent>
    </RowWrapper>
  );
};

export default BulletList;
