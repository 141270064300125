import React, { useState, useRef, useEffect } from "react";
import colors from "src/assets/styles/colors";
import typographySizes from "src/assets/styles/typographySizes";
import styled from "styled-components";
import mediaQuery from "src/assets/styles/mediaQuery";
import star from "src/assets/svg/star.svg";
import halfStar from "src/assets/svg/star_half.svg";
import { StaticQuery, graphql } from "gatsby";
import noHangers from "src/components/global/fn/noHangers.js";

const Wrapper = styled.span`
  position: relative;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  ::after {
    content: "";
    position: absolute;
    background-image: url(${star});
    background-size: contain;
    /* background-position: center; */
    background-repeat: no-repeat;
    height: 10rem;
    width: 10rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    position: initial;
    ::after {
      height: 6em;
      width: 6rem;
    }
  }
`;

const PopUp = styled.div`
  position: absolute;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  width: auto;
  visibility: none;
  width: 350rem;
  overflow-y: hidden;
  transform: translate(100%, -100%);
  right: 0;
  top: 0;
  background-color: ${colors.white};
  line-height: 1.5;
  z-index: 100;
  border: solid 3rem ${colors.dark};

  &.open {
    opacity: 1;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    visibility: visible;
    pointer-events: all;
    top: -20rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    left: 0;
    /* right: fixed; */
    position: absolute;
    top: initial;
    width: calc(100vw - 60rem);
    transform: translate(0%, calc(-100% - 30rem));
    &.open {
      top: initial;
      transform: translate(0%, calc(-100% - 50rem));
    }
  }
`;

const PopUpText = styled.div`
  font-size: ${typographySizes.s}rem;
  max-height: 200rem;
  overflow-y: scroll;
  display: inline-block;
  padding: 30rem 30rem;
  /* width */
  ::-webkit-scrollbar {
    width: 6rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${colors.white};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(
      0deg,
      rgba(100, 0, 179, 1) 0%,
      rgba(134, 184, 255, 1) 51%,
      rgba(211, 90, 255, 1) 100%
    );
    width: 3rem;
    border-top: 30rem ${colors.white} solid;
    border-right: 3rem ${colors.white} solid;
    border-bottom: 6rem ${colors.white} solid;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.dark};
  }
`;

const PopUpTitle = styled.span`
  font-size: ${typographySizes.m}rem;
  display: block;
  font-family: "DM Serif Display", serif;
  position: relative;
  top: 0;
  left: 0;
  background-color: ${colors.white};
  width: calc(100% - 30rem);
  padding-top: 20rem;
  padding-bottom: 20rem;
  padding-left: 30rem;
  margin: 0;

  border-bottom: solid 3rem ${colors.dark};
  ::after {
    content: "";
    position: absolute;
    bottom: -1.5rem;
    right: 0rem;
    background-image: url(${halfStar});
    height: 45rem;
    width: 66rem;
    transform: translateY(50%);
    z-index: 2;
  }
  ::before {
    content: "";
    position: absolute;
    bottom: -1.5rem;
    left: -3rem;
    background-image: url(${halfStar});
    height: 45rem;
    width: 69rem;
    transform: translateY(50%) rotate(180deg);
    z-index: 2;
  }
`;

const DictionaryComponent = ({ ...props }) => {
  const wrapperRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const openPop = () => {
    setIsOpen(isOpen ? false : true);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        isOpen &&
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);
  //   useOutsideAlerter(wrapperRef, isOpen, setIsOpen)

  return (
    <StaticQuery
      query={graphql`
        query DictionaryQuery {
          allStrapiDictionary {
            edges {
              node {
                Name
                Dictionary {
                  data {
                    Dictionary
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Wrapper onClick={openPop}>
          {props.text}
          <PopUp className={isOpen && "open"} ref={wrapperRef}>
            {props.text && <PopUpTitle> {props.text.toLowerCase()}</PopUpTitle>}

            <PopUpText>
              {props.tooltip && noHangers(props.tooltip)}

              {props.fromLib &&
                data.allStrapiDictionary.edges.map((pos) => (
                  <>
                    {pos.node.Name.toLowerCase() ===
                      props.fromLib.toLowerCase() &&
                      noHangers(pos.node.Dictionary.data.Dictionary)}
                  </>
                ))}
            </PopUpText>
          </PopUp>
        </Wrapper>
      )}
    />
  );
};

export default DictionaryComponent;
